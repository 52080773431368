var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page',[_c('page-head'),_c('page-body',[_c('div',{staticClass:"container mx-auto shadow-lg rounded-lg"},[_c('div',{staticClass:"flex flex-row justify-between bg-white"},[_c('div',{staticClass:"flex flex-col w-2/5 border-r-2 overflow-y-auto h-96"},[(_vm.newCons)?_c('div',_vm._l((_vm.newCons),function(index,key){return _c('ConItem',{key:key,attrs:{"item":index,"newMess":true},nativeOn:{"click":function($event){return _vm.setConversation(index)}}})}),1):_vm._e(),_vm._l((_vm.conversations),function(index,key){return _c('ConItem',{key:key,attrs:{"item":index,"newMess":false},nativeOn:{"click":function($event){return _vm.setConversation(index)}}})})],2),_c('div',{staticClass:"w-full px-5 flex flex-col justify-between"},[_c('div',{staticClass:"flex flex-col mt-5 overflow-y-auto h-96",attrs:{"id":"chatArea"}},[(_vm.currentConversation)?_c('div',_vm._l((_vm.currentConversation.messages),function(index,key){return _c('div',{key:key,staticClass:"flex mb-4",class:{
                    'justify-end': (index.type == 'callcenter'),
                    'justify-start': (index.type == 'user'),
                }},[_c('div',{staticClass:"mr-2 py-3 px-4 text-white",class:{
                    'bg-blue-400 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl': (index.type == 'callcenter'),
                    'bg-gray-400 rounded-br-3xl rounded-tr-3xl rounded-tl-xl': (index.type == 'user'),
                }},[_vm._v(" "+_vm._s(index.text)+" ")])])}),0):_vm._e()]),(_vm.currentConversation)?_c('div',{staticClass:"py-5"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.text),expression:"form.text"}],staticClass:"flex-auto bg-gray-300 py-3 px-3 rounded-xl",staticStyle:{"width":"46rem"},attrs:{"type":"text","placeholder":"type your message here..."},domProps:{"value":(_vm.form.text)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.sendMessage()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "text", $event.target.value)}}}),_c('button',{staticClass:"bg-blue-300 flex-auto w-auto py-3 px-4 rounded-xl ml-2",on:{"click":function($event){return _vm.sendMessage()}}},[_vm._v("Send")])]):_vm._e()])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }